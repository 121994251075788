@layer utilities {
  .datetime-m::-webkit-datetime-edit-day-field,
  .datetime-m::-webkit-datetime-edit-month-field {
    @apply mr-1 rounded;
  }
  .datetime-calendar-orange::-webkit-calendar-picker-indicator {
    @apply bg-calendar-orange;
  }
  .datetime-p::-webkit-datetime-edit-fields-wrapper,
  .datetime-p::-webkit-datetime-edit-day-field,
  .datetime-p::-webkit-datetime-edit-month-field,
  .datetime-p::-webkit-datetime-edit-year-field,
  .datetime-p::-webkit-calendar-picker-indicator {
    @apply p-4;
  }
  .datetime-p::-webkit-calendar-picker-indicator {
    @apply m-0;
  }
  .datetime-field-bg-japanese_indigo-light::-webkit-datetime-edit-day-field,
  .datetime-field-bg-japanese_indigo-light::-webkit-datetime-edit-month-field,
  .datetime-field-bg-japanese_indigo-light::-webkit-datetime-edit-year-field,
  .datetime-field-bg-japanese_indigo-light::-webkit-calendar-picker-indicator {
    @apply bg-japanese_indigo-light;
  }
  .datetime-text-none::-webkit-datetime-edit-text {
    display: none;
  }
  .datetime-width-min::-webkit-datetime-edit {
    max-width: 190px;
  }
  @media only screen and (max-width: 600px) {
    .datetime-width-min::-webkit-datetime-edit {
      max-width: 70%;
    }
  }
}
